import React from 'react'
import { useNavigate } from 'react-router-dom'
import '../options/optionsPage.scss'

const id = 1
const OptionsPage = (): JSX.Element => {
    const navigate = useNavigate()
    const handleProductDetail = () => {
        // ทำการ navigate ไปยังหน้า /home/1
        navigate(`/home/${id}`)
    }

    return (
        <div className="fix">
            <div className="container-option">
                <h2>Select your options</h2>
                <div className="image-products">
                    <div className="container" onClick={handleProductDetail}>
                        <img
                            className="image"
                            src="/images/BannerImage.png"
                            alt="choose_option"
                        />
                        <div className="text-overlay">
                            Choose Your Preferred Option for Generating Your PD
                        </div>
                    </div>
                    <div className="container" onClick={handleProductDetail}>
                        <img
                            onClick={handleProductDetail}
                            src="/images/lineOA.png"
                            alt="lineOA"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OptionsPage

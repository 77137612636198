import React, { useState } from 'react'
import { Form, FormInstance } from 'antd'
import { FieldData } from 'rc-field-form/lib/interface'
import { PostVerifyQR } from '../../../model/interface/response/payment'
import { useAuth } from '../../../contexts/AuthContext'
import { UploadImg } from './upload'
import { PaymentField } from '../../../model/enum/payment'

interface SlipFormProps {
    form: FormInstance
    onSubmit: (data: PostVerifyQR) => void
    onFormChange?: (data: PostVerifyQR) => void
    productID: string
    price: number
}

export const SlipForm = (props: SlipFormProps): JSX.Element => {
    const { form, onSubmit } = props
    const { user } = useAuth()
    const [docID, setDocID] = useState('')
    const hookSubmit = async (values: PostVerifyQR) => {
        if (user) {
            onSubmit({
                ...values,
                [PaymentField.PAYMENT_AMOUNT]: props.price,
                [PaymentField.PAYMENT_ID]: docID,
            })
        }
    }

    const onChangeForm = (_: FieldData[], allFields: PostVerifyQR) => {
        if (props.onFormChange) {
            props.onFormChange(allFields)
        }
    }

    const handleUploadSuccess = (url: string, id: string) => {
        setDocID(id)
        form.setFieldsValue({
            [PaymentField.PAYMENT_URL]: url,
        })
    }

    return (
        <Form
            layout="vertical"
            name="basic"
            form={form}
            onFinish={hookSubmit}
            onValuesChange={onChangeForm}
            autoComplete="off"
        >
            <Form.Item
                style={{ marginBottom: 0 }}
                name={[PaymentField.PAYMENT_URL]}
                rules={[
                    {
                        validator: async (_, value) => {
                            if (!value) {
                                return Promise.reject('กรุณาอัปโหลดรูปภาพ slip')
                            }
                        },
                    },
                ]}
            >
                <UploadImg
                    onUploadImgSuccess={(url, id) => {
                        handleUploadSuccess(url, id)
                    }}
                    productID={props.productID}
                />
            </Form.Item>
        </Form>
    )
}

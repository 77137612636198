import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import Title from 'antd/es/typography/Title'
import './index.scss'
import { ProductService } from '../../services/product.service'
import { useNavigate, useParams } from 'react-router-dom'
import LoadingBox from '../../components/loading'
import { ProductData } from '../../model/interface/response/products'

const ProductDetail = (): JSX.Element => {
    const { id } = useParams()
    const [productDetail, setProductDetail] = useState<ProductData>()
    const [loading, setLoading] = useState<boolean>(false)
    const navigate = useNavigate()
    // const lineOA = 'https://lin.ee/FSjzmdE'
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (id) {
                    setLoading(true)
                    const productDetailData =
                        await ProductService.getProductNotPaidById(id)
                    setProductDetail(productDetailData)
                    setLoading(false)
                }
            } catch (error) {
                setLoading(false)
            }
        }
        fetchData()
    }, [])

    return (
        <>
            {!loading && productDetail ? (
                <div className="product-details">
                    <div className="banner-web">
                        <div className="carousel">
                            <img
                                className="image-scale slide"
                                style={{ objectFit: 'cover' }}
                                src={productDetail.banner}
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="banner-mobile">
                        <div className="carousel">
                            <img
                                className="image-scale slide"
                                src={
                                    productDetail.bannerMobile ||
                                    productDetail.banner
                                }
                                alt=""
                            />
                        </div>
                    </div>

                    <div className="info-web">
                        <div className="detail-container">
                            <div className="text-container">
                                <Title level={2} style={{ marginTop: '0px' }}>
                                    {productDetail.title}
                                </Title>
                                <div
                                    className="text-description"
                                    dangerouslySetInnerHTML={{
                                        __html: productDetail.description,
                                    }}
                                ></div>
                                <Button
                                    type="primary"
                                    size="large"
                                    style={{
                                        width: '50%',
                                        marginBottom: '40px',
                                    }}
                                    onClick={() => {
                                        // window.open(lineOA, '_blank')
                                        navigate(`/order/${id}`)
                                    }}
                                >
                                    สั่งซื้อ
                                </Button>
                            </div>
                            {productDetail.otherImageUrl &&
                                productDetail.otherImageUrl?.length > 0 && (
                                    <div className="img-container">
                                        {productDetail.otherImageUrl[0] && (
                                            <div className="left-img">
                                                <img
                                                    className="img1"
                                                    src={
                                                        productDetail
                                                            .otherImageUrl[0]
                                                    }
                                                    alt=""
                                                />
                                            </div>
                                        )}
                                        {productDetail.otherImageUrl[1] && (
                                            <div className="right-img">
                                                <img
                                                    className="img2"
                                                    src={
                                                        productDetail
                                                            .otherImageUrl[1]
                                                    }
                                                    alt=""
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                        </div>
                    </div>

                    <div className="info-mobile">
                        <div className="detail-container">
                            {productDetail.otherImageUrl &&
                                productDetail.otherImageUrl?.length > 0 && (
                                    <div className="img-container">
                                        {productDetail.otherImageUrl[0] && (
                                            <div className="left-img">
                                                <img
                                                    className="img1"
                                                    src={
                                                        productDetail
                                                            .otherImageUrl[0]
                                                    }
                                                    alt=""
                                                />
                                            </div>
                                        )}
                                        {productDetail.otherImageUrl[1] && (
                                            <div className="right-img">
                                                <img
                                                    className="img3"
                                                    src={
                                                        productDetail
                                                            .otherImageUrl[1]
                                                    }
                                                    alt=""
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                            <div className="text-container">
                                <Title level={2}>{productDetail.title}</Title>
                                <div
                                    className="text-description"
                                    dangerouslySetInnerHTML={{
                                        __html: productDetail.description,
                                    }}
                                ></div>
                                <Button
                                    type="primary"
                                    size="large"
                                    style={{
                                        width: '40%',
                                        marginBottom: '40px',
                                    }}
                                    onClick={() => {
                                        navigate(`/order/${id}`)
                                    }}
                                >
                                    สั่งซื้อ
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <LoadingBox />
            )}
        </>
    )
}

export default ProductDetail
